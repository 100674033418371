"use strict";

// var app = angular.module('app');

twaCC.factory('AssociatesSummaryService', function($q, $log, UtilsService, Constants, $rootScope, Configuration, Http, EntitiesService) {

    var api = {

        loadAssociatesSummary: function(filter, stateFun) {
            return Http.get("api/associates-summary")
                .then(function(summaries) {
                    if (filter) {
                        summaries = summaries.filter(filter);
                    }
                    summaries = summaries.map(
                        summary => api.enrichAssociateSummary(summary)
                    );
                    if (stateFun) {
                        const fiscalCodesMap = {};
                        summaries.forEach(summary => {
                            if (summary.ssn) {
                                if (fiscalCodesMap[summary.ssn]) {
                                    fiscalCodesMap[summary.ssn].isDuplicated = true;
                                    summary.isDuplicated = true;
                                } else {
                                    fiscalCodesMap[summary.ssn] = summary;
                                }
                            }
                        });
                        summaries = summaries.map(
                            summary => stateFun(summary)
                        );
                    }
                    return summaries;
                })
                .catch(function(error) {
                    throw new ApplicationException("can't loadAssociatesSummary", "can't loadAssociatesSummary", error);
                })
        },

        enrichAssociateSummary: function(summary) {
            summary.isQuotaAssociativaPayed = api.isQuotaAssociativaPayed(summary);
            summary.isActive = summary.isQuotaAssociativaPayed || (
                summary.expirationDate &&
                moment().isSameOrBefore(summary.expirationDate, 'day')
            );
            var res = EntitiesService.computeTotalToPay(summary.nextPayments || []);
            summary.firstPaymentDate = res.firstPaymentDate;
            summary.toBePayedAmount = res.totalToPay;
            return summary;
        },

        /*
         is last association fee payed valid now?
         */
        isQuotaAssociativaPayed: function(summary) {
            return window.shared.AssociatesSummaryShared.isQuotaAssociativaPayed(summary);
        },

        loadAssociateSummary: function(assocSumId) {
            return Http.get("api/associates-summary/" + assocSumId)
                .then(function(summary) {
                    summary = api.enrichAssociateSummary(summary);
                    return summary;
                })
        },

        //could return just the data needed to identify unique emails, ssn...
        loadOtherAssociatesSummary: function(associateId) {
            var summaries = api.loadAssociatesSummary(
                function(summary) {
                    if (summary.fullDocId == associateId) {
                        return false;
                    } else {
                        return true;
                    }
                });
            return summaries;
        },

        computeAll: function() {
            return Http.post("api/associates-summary/compute")
        }
    };

    return api;
});
