function CmActivityScheduleCalendarController($scope, $element, $attrs, Constants, CalendarSchedulesService, Http, $q,
    $location, $log, $timeout, UtilsService, MessageBox, EntitiesService, $routeParams, $rootScope,
                                              AuthenticationService, AttendanceService) {
    let self = this;
    CmScheduleCalendarController.call(this, $scope, $element, $attrs, Constants, CalendarSchedulesService, Http, $q,
        $location, $log, $timeout, UtilsService, MessageBox, EntitiesService, $routeParams, $rootScope);

    this.$onInit = function() {
        self.SCHEDULE_ID_PREFIX = "courseSchedule";
        self.SCHEDULE_FIELD_IDENTIFYING_RESOURCE = 'roomId';
        self.LOAD_EVENTS_URL_PREFIX = 'api/activities/events';
        self.MODAL_TEMPLATE_URL = 'bower_components/twa-common-components/frontend/src/activities-schedule/courseschedule-edit.html';
        self.MODAL_CONTROLLER = "ActivitiesScheduleModalController";
        self.VIEWS_NAME = ['agendaDay', 'agendaWeek', 'month'];
        self.INITIAL_RESOURCE_ID = 'NOT_EXISTING_RESOURCE';

        //self.selectedCollaboratorId = 'associate_4078516503479277469385624092840'; //TODO start null and change through event

        let calendarOptions = self.buildCalendarOptions();
        self.init(calendarOptions);
    };

    $scope.$on('printScheduleCalendar', (event, data) => {
        let collaboratorId = self.priv.backgroundResourceId;
        let searchObj = $location.search();
        let viewName = searchObj.view;
        if (collaboratorId && viewName == "agendaDay"){
            let date = searchObj.date;
            let collaboratorName = null;
            let rows = [];
            self.priv.events.forEach(event => {
                if (event.instructorId == collaboratorId){
                    let appointment = {
                        start: moment(event.scheduleStartDate).format("H:mm"),
                        end: moment(event.scheduleEndDate).format("H:mm"),
                        activityName: event.activityName,
                        participants: event.participants,
                        participantsId: event.participantsId,
                        notes: event.notes
                    };
                    if (event.instructorName){
                        collaboratorName = event.instructorName;
                    }
                    rows.push(appointment);
                }
            })
            let appointments = {
                date: date,
                collaboratorName: collaboratorName,
                rows: rows
            }
            Http.post("/api/activities/schedule/print", appointments, {responseType: "blob"})
                .then(blob => {
                    i=0;
                    const documentDate = date;
                    UtilsService.downloadBlob(blob, `appuntamenti_${collaboratorName}_${documentDate}.pdf`);
                })
        }
    });

    this.decorateCalEvent = function(calEvent, event) {
        calEvent.title = event.activityName;
        calEvent.instructorId = event.instructorId;
        calEvent.instructorName = event.instructorName;
        calEvent.courseId = event.activityScheduleId;
        calEvent.lessonId = event.activityAppointmentId;
        calEvent.lessonIdx = event.activityAppointmentIdx;
        calEvent.resourceId = event.roomId;
        calEvent.participants = event.participants;
        calEvent.backgroundColor = event.color;
        calEvent.schedulingType = event.schedulingType;
        calEvent.scheduleStartDate = event.scheduleStartDate;
        calEvent.scheduleEndDate = event.scheduleEndDate;
        calEvent.notes = event.notes;
        if (event.attendance) {
            // calEvent.attendance = event.attendance.attendance;
            // calEvent.attendanceInstructorName = event.attendance.instructorName;
            calEvent.attendanceDocId = event.attendance.docId;
            calEvent.attendanceDocRev = event.attendance.docRev;
        }
    };

    this._eventRender = function(event, element, view) {
        let eventTitle = event.title;
        /*if (event.attendance) {
            eventTitle += " (" + event.attendance + ")";
        }*/
        let backgroundColor;
        // if (event.color) {
        //     backgroundColor = event.color;
        // } else
        if (event.resourceId && self.resourcesMap && self.resourcesMap[event.resourceId] && self.resourcesMap[event.resourceId].color) {
            event.backgroundColor = self.resourcesMap[event.resourceId].color
        }

        let contentClass = 'fc-content-white';

        if (event.backgroundColor && darkContent(event.backgroundColor)) {
            contentClass = 'fc-content-black';
        }
        let eventHtml = '';

        let startTime = event.start.format('HH:mm');
        //event.end == null if startDate == endDate
        let endTime = event.end ? event.end.format('HH:mm') : "";
        let timeSpan = startTime + " " + endTime;
        if (!event.allDay) {
            eventHtml += '<div class="fc-time ' + contentClass + '"><span>' + timeSpan + '</span></div>';
            eventHtml += '<div class="fc-main">';
        }
        const activitiNameFrag = `<div class="fc-title ${contentClass}"><span>${eventTitle}</span></div>`;
        let participantsFrag = null;
        let instructorFrag = null;
        if (event.participants && event.participants.length > 0 && event.participants[0]) {
            let participants = event.participants[0].toLowerCase();
            let otherParticipants = "";
            if (event.participants.length > 1) {
                otherParticipants += "...";
            }
            participantsFrag = '<div class="' + contentClass + '"><span class="fc-participants">' + participants +
                '</span>' + otherParticipants + '</div>';
        } else if (event.instructorName) {
            instructorFrag = '<div class="fc-instructor ' + contentClass + '"><span>' + event.instructorName.toLowerCase() + '</span></div>';
        }
        if (participantsFrag) {
            eventHtml += participantsFrag;
            eventHtml += activitiNameFrag;
        } else {
            eventHtml += activitiNameFrag;
        }
        if (instructorFrag) {
            eventHtml += instructorFrag;
        }
        if (!event.allDay) {
            eventHtml += '</div>';
        }
        eventHtml += '</div>';
        element.find(".fc-content")
            .html(eventHtml);

        function darkContent(str) {
            const red = parseInt(str.substr(1, 2), 16);
            const green = parseInt(str.substr(3, 2), 16);
            const blue = parseInt(str.substr(5, 2), 16);

            return (red * 0.299 + green * 0.587 + blue * 0.114) > 186 ? true : false;
        }
    };

    this._eventAfterRender = function(event, element, view) {
        let tooltipTitle = event.title;

        let text = "";
        if (event.instructorName) {
            text += "<h6>gestita da:</h6>" +
                "<div style='padding-left: 20px;'>" + event.instructorName + "</div>";
        }
        if (event.attendanceInstructorName && event.instructorName != event.attendanceInstructorName) {
            text += "<h6>erogata da:</h6>" +
                "<div style='padding-left: 20px;'>" + event.attendanceInstructorName + "</div>";
        }
        if (event.attendance) {
            text += "<h6>presenze: " + event.attendance + "</h6>";
        }
        if (event.participants) {
            text += "<h6>partecipanti: </h6>" +
                "<ul>";
            event.participants.forEach(function(participant) {
                text += "<li>" + participant + "</li>";
            });
            text += "</ul>";
        }
        if (event.repetition == Constants.REPETITION_WEEKLY || event.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {
            text += "<h6>Ripetitione settimanale:</h6>" +
                "<div style='padding-left: 20px;'>" + moment(event.scheduleStartDate).format('DD/MM/YYYY') +
                ' - ' + moment(event.scheduleEndDate).format('DD/MM/YYYY') + "</div>";
        }
        if (event.notes) {
            text += "<h6>Note</h6>" +
                "<pre>" + event.notes + "</pre>";
        }

        let isTouchDevice = ('ontouchstart' in document.documentElement);
        const showEvent = isTouchDevice ? "taphold" : "mouseenter"; //disabling for touch devices
        element.qtip({
            content: {
                title: {
                    text: tooltipTitle,
                },
                text: text
            },
            position: {
                my: 'bottom center',
                at: 'top center',
                viewport: $('#courses-calendar')
            },
            show: {
                event: showEvent,
                effect: function() {
                    $(this).fadeTo(500, 1);
                },
                solo: true
            },
            style: {
                classes: "qtip-default qtip-rounded qtip-shadow",
            },
            hide: {
                fixed: false,
                effect: function() {
                    $(this).fadeTo(500, 0);
                },
                //event: 'unfocus'
            }

        });
    };

    this.removeTooltips = function() {
        $('.qtip')
            .each(function() {
                let tooltip = $(this);
                if (tooltip.attr('style')) {
                    tooltip.remove();
                }
            });
    };

    this._drawCalendarResources = function(callback) {
        self.resourcesMap = self.resources.reduce(function(acc, room) {
            acc[room._id] = room;
            return acc;
        }, {});
        let resources = self.resources.map(function(room) {
            return {
                id: room._id,
                title: room.name,
                eventColor: room.color
            }
        });
        if (callback){
            callback(resources);
        }
    };

    this.backgroundEventsFilter = function(backgroundEvent) {
        return self.priv.backgroundResourceId == backgroundEvent.backgroundResourceId;
        //return true;
    };

    /*hotkeys.bindTo($scope)
        .add({
            combo: 'ctrl+a',
            description: 'Aggiungi corso',
            callback: function() {
                $scope.createEmptyCourse();
            }
        })
        .add({
            combo: 'ctrl+m',
            description: 'Modifica corso',
            callback: function() {
                $scope.editCourse();
            }
        })
        .add({
            combo: 'ctrl+l',
            description: 'Aggiungi lezione',
            callback: function() {
                $scope.addLesson();
            }
        })
        .add({
            combo: 'ctrl+o',
            description: 'Cancella corso',
            callback: function() {
                $scope.deleteCurrentCourse();
            }
        })
        .add({
            combo: 'ctrl+e',
            description: 'Cancella lezione',
            callback: function() {
                $scope.deleteCurrentLesson();
            }
        });*/

    this.eventAllow = function(dropInfo, draggedEvent) {
        let activityEvent = self.selectedEvent;
        let allowed = true;
        //$log.debug(JSON.stringify(dropInfo));
        if (self.selectedEvent.activityAppointmentHasAttendance || !self.canModifySchedule()) {
            allowed = false;
        } else {
            //interpret ambiguously zoned date (no tz info) as date in local tz
            let eventStart = moment(dropInfo.start.format());
            //$log.debug("dropDate: "+eventDropDate.format());
            const isWeeklyRepetition = activityEvent.repetition == Constants.REPETITION_WEEKLY ||
                activityEvent.schedulingType == Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS;
            allowed = CalendarSchedulesService.isAppointmentDateVisibleAfterMove(eventStart,
                activityEvent.scheduleStartDate, activityEvent.scheduleEndDate, isWeeklyRepetition);
            if (allowed) {
                let eventEnd = moment(dropInfo.end.format());
                allowed = !doesEventOverlapCollaboratorActivities(eventStart, eventEnd);
            }
        }
        //$log.debug("event allowed: "+allowed);
        return allowed;
    };

    this.canModifySchedule = function() {
        let authority = AuthenticationService.getCurrentAuthority();
        if (authority.userRole == Constants.USER_ROLE_ACTIVITY_MANAGER) {
            let userid = authority.userid;
            return self.selectedEvent &&
                self.selectedEvent.instructorId == userid;
        } else {
            return true;
        }
    };

    function doesEventOverlapCollaboratorActivities(eventStart, eventEnd, dropToAllDay) {
        let overlaps = false;
        if (self.selectedEvent && self.selectedEvent.instructorId) {
            let selectedActivity = self.selectedEvent;

            let activities = getCollaboratorActivityEvents(selectedActivity.instructorId);
            let otherActivities = activities.filter(function(ev) {
                return ev.id != selectedActivity.id;
            });
            //$log.debug(startDate.format()+"-"+endDate.format()+" overlaps ");
            overlaps = otherActivities.reduce(function(acc, event) {
                if (eventStart.isBetween(event.startDate, event.endDate) ||
                    eventEnd.isBetween(event.startDate, event.endDate) ||
                    (eventStart.isSameOrBefore(event.startDate) && eventEnd.isSameOrAfter(event.endDate))
                ) {
                    //$log.debug(startDate.format()+"-"+endDate.format()+" overlaps "+ moment(event.startDate).format()+"-"+moment(event.endDate).format() );
                    return true;
                } else {
                    return acc;
                }
            }, false);
        }
        return overlaps;
    }

    function getCollaboratorActivityEvents(collaboratorId) {
        let res = self.priv.events.filter(function(ev) {
            return collaboratorId == ev.instructorId;
        });
        return res;
    }

    self.buildModalMessage = function(isCreatingSchedule, schedule, appointment) {
        let modalMessage = self.buildStandardModalMessage(isCreatingSchedule, schedule, appointment);

        modalMessage.courses = self.courses;
        modalMessage.instructors = self.instructors;
        modalMessage.associates = self.associates;
        modalMessage.rooms = self.resources;
        modalMessage.colorList = self.colorList;

        //TODO: handle edit disabled in base component

        return modalMessage;
    };

    this.saveActivityAttendanceAction = function(command, isCreatingSchedule){
        const activityAttendance = command.entity;
        const promise = AttendanceService.saveAttendance(activityAttendance)
            .then(function(ack) {
                //$scope.lessonScheduleHasAttendance = true;
                self.drawCalendarEvents({
                    load: true
                });
            });
        return promise;
    };

    this.deleteActivityAttendanceAction = function(command, isCreatingSchedule){
        const activityAttendance = command.entity;
        const promise = EntitiesService.delete(activityAttendance)
            .then(function(ack) {
                self.drawCalendarEvents({
                    load: true
                });
            });
        return promise;
    }

}

angular.module('activitiesScheduleComponents')
    .component('cmActivityScheduleCalendar', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-schedule-calendar.template.html',
        controller: CmActivityScheduleCalendarController,
        bindings: {
            resources: '<',
            currentRoomId: '<',
            activitiesMap: '<',
            courses: '<',
            instructors: '<',
            associates: '<'
        }
    });
