function CmAppointmentController($scope, $element, $attrs, UIService, uiGridConstants, CalendarSchedulesService) {
    this.$onInit = function() {
        this.appointment.startDate = moment(this.appointment.startDate).second(0).milliseconds(0).toDate();
        this.appointment.endDate = moment(this.appointment.endDate).second(0).milliseconds(0).toDate();
        this.appointmentDuration = moment(this.appointment.endDate).diff(this.appointment.startDate, 'minutes');
        return;
    };

    this.allDayChanged = function() {
        if (this.appointment.allDay) {
            this.appointment.startDate = moment(this.appointment.startDate).hour(0).minute(0).second(0).toDate();
        } else {
            this.appointment.startDate = moment(this.appointment.startDate).hour(12).minute(0).second(0).toDate();
        }
        this.initEndDate();
        if (this.activitySchedule) {
            CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        }
    };

    this.initEndDate = function() {
        if (this.appointment.startDate) {
            if (this.appointment.allDay) {
                this.appointment.endDate = moment(this.appointment.startDate)
                    .add(1, "days")
                    .toDate();
            } else {
                this.appointment.endDate = moment(this.appointment.startDate)
                    .add(this.appointmentDuration, "minutes")
                    .toDate();
            }
        }
    };

    this.startDateChanged = function() {
        this.initEndDate();
        if (this.activitySchedule) {
            CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        }
    };

    this.endDateChanged = function() {
        if (moment(this.appointment.startDate).isSameOrAfter(this.appointment.endDate)) {
            this.initEndDate()
        }
        if (this.activitySchedule) {
            CalendarSchedulesService.compactActivityScheduleDates(this.activitySchedule);
        }
    };

    $scope.$on('courseDurationChanged', (event, duration) => {
        this.appointmentDuration = duration;
        this.initEndDate();
    });

}

angular.module('activitiesScheduleComponents')
    .component('cmAppointment', {
        templateUrl: 'bower_components/twa-common-components/frontend/src/activities-schedule/cm-appointment.studio.template.html',
        controller: CmAppointmentController,
        bindings: {
            appointment: '<',
            duration: '<',
            minDate: '<',
            maxDate: '<',
            activitySchedule: '<' //!= null for singleAppointment
        }
    });
