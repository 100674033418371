if (typeof window == "undefined") {
    var moment = require("moment");
    var Constants = require("../backend/src/Constants.js");
} else {
    var Constants = window.shared.constants;
}

var api = {

    /*
        make course start and end dates close to lessons interval
        (in case of weekly repetition course end date is free)
     */
    compactActivityScheduleDates: function(courseSchedule) {
        if (courseSchedule.lessons && courseSchedule.lessons.length != 0) {

            if ((courseSchedule.repetition && courseSchedule.repetition == Constants.REPETITION_WEEKLY) || //for backward compatibility
                courseSchedule.schedulingType != Constants.SCHEDULING_TYPE_WEEKLY_APPOINTMENTS) {

                const firstLessonStartDate = courseSchedule.lessons.reduce(function(acc, lesson, lessonIdx) {
                    if (moment(lesson.startDate).isBefore(acc))
                        return lesson.startDate;
                    return acc;
                }, courseSchedule.lessons[0].startDate);

                const lastLessonEndDate = courseSchedule.lessons.reduce(function(prev, lesson) {
                    if (moment(lesson.endDate).isAfter(prev))
                        return lesson.endDate;
                    return prev;
                }, courseSchedule.lessons[0].endDate);

                courseSchedule.startDate = moment(firstLessonStartDate).toDate();
                courseSchedule.endDate = moment(lastLessonEndDate).toDate();
            } else {
                courseSchedule.startDate = moment(courseSchedule.startDate).startOf("day").toDate();
                if (moment(courseSchedule.endDate).diff(courseSchedule.startDate, "days") < 6) {
                    courseSchedule.endDate = moment(courseSchedule.startDate).add(6, "days").toDate();
                }
                courseSchedule.endDate = moment(courseSchedule.endDate).endOf("day").toDate();
            }
        }
    },

    /*
        Lessons are always in the first seven days after course start
        movements are relative (doesn't matter which event is selected) and constrained to one week
     */
    compactLessons: function(courseSchedule) {
        const courseStartYear = moment(courseSchedule.startDate).year();
        const courseStartWeek = moment(courseSchedule.startDate).week();
        courseSchedule.lessons.forEach(function(lesson, idx) {
            let week = courseStartWeek;
            if (moment(lesson.startDate).weekday() < moment(courseSchedule.startDate).weekday()) {
                week += 1;
            }
            let lessonStartDay = moment(lesson.startDate).day();
            lesson.startDate = moment(lesson.startDate).year(courseStartYear).week(week).day(lessonStartDay).toDate();
            let lessonEndDay = moment(lesson.endDate).day();
            lesson.endDate = moment(lesson.endDate).year(courseStartYear).week(week).day(lessonEndDay).toDate();
        });
    },

    isAppointmentDateVisibleAfterMove: function(appointmentStartDate, activityScheduleStartDate,
                                                activityScheduleEndDate, isWeeklyRepetition) {
        if (isWeeklyRepetition) {
            //allow move outside schedule interval
            /*if (moment(activityScheduleEndDate).diff(activityScheduleStartDate, 'weeks') < 1)){
                var firstCourseWeek = moment(activityScheduleStartDate)
                    .week();
                var dateInFirstWeek = moment(appointmentStartDate)
                    .week(firstCourseWeek);
                var lastCourseWeek = moment(activityScheduleEndDate)
                    .week();
                var dateInLastWeek = moment(appointmentStartDate)
                    .week(lastCourseWeek);

                return (
                    dateInFirstWeek.isBetween(activityScheduleStartDate, activityScheduleEndDate, 'day', '[]') ||
                    dateInLastWeek.isBetween(activityScheduleStartDate, activityScheduleEndDate, 'day', '[]')
                )
            }*/
            //don't allow move outside schedule interval (edit appointments in schedule for that)
            return moment(appointmentStartDate).isBetween(activityScheduleStartDate, activityScheduleEndDate, 'day', '[]')
        }
        return true;
    }
};

if (typeof window == "undefined") {
    module.exports = api;
} else {
    if (!window.shared) {
        window.shared = {};
    }
    window.shared.CalendarSchedulesShared = api;
}
